    #root {
      height: 100%;
    }

    article .reach-limit-button {
      background: linear-gradient(90.51deg, #CFC8D7 56.94%, #CFC8D7 145.88%);
    }

    .bg-black {
      background: black;
    }

    .loader {
      display: flex;
      column-gap: 16px;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 50000;
      margin: 0 auto;
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.5);
    }

    .dot {
      width: 32px;
      height: 32px;
      background-color: black;
      border-radius: 50%;
      animation: loading 0.5s infinite alternate;
    }

    .dot:nth-child(1) {
      background-color: #E70793;
      animation-delay: -0.25s;
    }

    .dot:nth-child(2) {
      background-color: #E117E6;
      animation-delay: -0.5s;
    }

    .dot:nth-child(3) {
      background-color: #57248E;
      animation-delay: -0.75s;
    }

    .dot:nth-child(4) {
      background-color: #34a853;
      animation-delay: -1s;
    }

    @keyframes loading {
      0% {
        transform: translateY(-15px);
      }

      100% {
        transform: translateY(5px);
      }
    }

    .opacity-4 {
      opacity: .4;
    }
    .disabled\:opacity-50:disabled{
      opacity: .5;
    }
    .disabled\:cursor-not-allowed:disabled{
      cursor:not-allowed;
    }
    .text-pink-600 {
      color:rgba(219 39 119, 1)
    }