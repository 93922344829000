@font-face {
    font-family: 'Proxima Nova';
    src: url('../fonts/ProximaNova-Semibold.woff2') format('woff2'),
        url('../fonts/ProximaNova-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('../fonts/ProximaNova-Medium.woff2') format('woff2'),
        url('../fonts/ProximaNova-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('../fonts/ProximaNova-Extrabld.woff2') format('woff2'),
        url('../fonts/ProximaNova-Extrabld.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('../fonts/ProximaNova-Black.woff2') format('woff2'),
        url('../fonts/ProximaNova-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('../fonts/ProximaNova-RegularIt.woff2') format('woff2'),
        url('../fonts/ProximaNova-RegularIt.woff') format('woff');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('../fonts/ProximaNova-Bold.woff2') format('woff2'),
        url('../fonts/ProximaNova-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('../fonts/ProximaNovaT-Thin.woff2') format('woff2'),
        url('../fonts/ProximaNovaT-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('../fonts/ProximaNova-Regular.woff2') format('woff2'),
        url('../fonts/ProximaNova-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

